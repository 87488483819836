import React from "react";
import Layout from "../../components/Layout/Layout";
import contactUsImage from "../../images/contactUs.jpg";
import { Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import "./SearchTool.css";
import {
  faSearch,
  faMapMarkerAlt,
  faList,
  faDollarSign,
  faTractor,
} from "@fortawesome/free-solid-svg-icons";

const SearchTool = () => {
  return (
    <Layout>
      <>
        <Row className="m-5">
          <Col lg={9} md={9} sm={12}>
            <div className="container-icons">
              <NavLink to="/multisearch" className="icon-container">
                <div className="icon-bg search">
                  <FontAwesomeIcon icon={faSearch} size="2x" />
                </div>
                <h5>Multisearch</h5>
              </NavLink>

              <NavLink to="/search-by-state" className="icon-container">
                <div className="icon-bg map">
                  <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
                </div>
                <h5>Search By State/Territory</h5>
              </NavLink>

              <NavLink to="/search-by-task" className="icon-container">
                <div className="icon-bg list">
                  <FontAwesomeIcon icon={faList} size="2x" />
                </div>
                <h5>Search By Task</h5>
              </NavLink>

              <NavLink to="/vac-search" className="icon-container">
                <div className="icon-bg dollar">
                  <FontAwesomeIcon icon={faDollarSign} size="2x" />
                </div>
                <h5>VAC Search</h5>
              </NavLink>

              <NavLink to="/dama" className="icon-container">
                <div className="icon-bg tractor">
                  <FontAwesomeIcon icon={faTractor} size="2x" />
                </div>
                <h5>DAMA</h5>
              </NavLink>
            </div>

            <div className="search-container">
              <FontAwesomeIcon icon={faSearch} className="fa-search" />
              <input type="text" placeholder="Type code or occupation name" />
            </div>
          </Col>

          <Col lg={3} md={3} sm={12} className="contact-us-image-col">
            <Card className="mb-4">
              <Card.Img variant="top" src={contactUsImage} alt="Google Ad" />
              <Card.Body>
                <Card.Title>Sponsored by Google</Card.Title>
                <Card.Text>
                  This is an ad by Google. Explore the latest services and
                  offers to help you with your migration journey.
                </Card.Text>
                <a
                  href="https://www.google.com"
                  className="btn btn-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    </Layout>
  );
};

export default SearchTool;
