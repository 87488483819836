import React, { useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  MenuItem,
  Drawer,
  List,
  ListItem,
  Box,
  Popover,
  useMediaQuery,
  useTheme,
  Typography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logo from "../../../src/images/logo.png";
import { useAuth } from "../../context/Auth";
import "./Header.css";

const Header = () => {
  const { auth, setAuth } = useAuth(); // Corrected destructuring
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuType, setMenuType] = useState(null);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [open, setOpen] = useState(false);

  const timeoutId = useRef(null);
  let timer;

  const handleMenuOpen = (event, type) => {
    // Clear any existing timer
    setAnchorEl(event.currentTarget);
    setMenuType(type);
    setOpen(true);
  };
  const handleMenuClose = () => {
    timer = setTimeout(() => {
      setOpen(false);
      setAnchorEl(null);
      setMenuType(null);
    }, 1200);
  };
  const toggleSearch = () => {
    setSearchOpen(!isSearchOpen);
  };
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
  };
  const renderMenu = (type) => {
    switch (type) {
      case "about":
        return (
          <>
            <MenuItem className="menu-item" component={NavLink} to="/about">
              About
            </MenuItem>
            <MenuItem className="menu-item" component={NavLink} to="/contact">
              Contact Us
            </MenuItem>
            <MenuItem className="menu-item" component={NavLink} to="/team">
              Our Team
            </MenuItem>
            <MenuItem className="menu-item" component={NavLink} to="/services">
              Services
            </MenuItem>
          </>
        );
      case "visas":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "10px",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ marginRight: "25px" }}>
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", marginBottom: "10px" }}
                className="visa-type "
                variant="h6"
              >
                Skilled Visas
              </Typography>
              <ul className="menu-item-li">
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/skilledindependent-visa-189"
                  >
                    Skilled Independent (Subclass 189)
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/skilled-nominated-visa-190"
                  >
                    Skilled Nominated (Subclass 190)
                  </Link>
                </li>
                <li>
                  <Link className="menu-item-li-a" to="/skilled-work-visa-491">
                    Skilled Work Regional (Subclass 491)
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/permanent-residence-visa-191"
                  >
                    Permanent Residence Regional (Subclass 191)
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/skilled-residence-visa-887"
                  >
                    Skilled Residence (Subclass 887)
                  </Link>
                </li>
              </ul>

              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", marginBottom: "10px" }}
                className="visa-type"
                variant="h6"
              >
                TEMPORARY ACTIVE VISAS
              </Typography>
              <ul className="menu-item-li">
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/temporary-activity-408-visa"
                  >
                    Temporary Activity (Subclass 408)
                  </Link>
                </li>
                <li>
                  <Link className="menu-item-li-a" to="/short-stay-visa-400">
                    Temporary Work (Short Stay Specialist) (Subclass 400)
                  </Link>
                </li>
              </ul>
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", marginBottom: "10px" }}
                className="visa-type"
                variant="h6"
              >
                EMPLOYER SPONSORED VISAS
              </Typography>
              <ul className="menu-item-li">
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/temporary-skills-shortage-visa-482"
                  >
                    Temporary Skill Shortage (Subclass 482)
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/skilled-employer-sponsored-regional-494-visa"
                  >
                    Skill Employer Sponsored Regional (Subclass 494)
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/employer-nomination-186-visa"
                  >
                    Employer Nomination (Subclass 186)
                  </Link>
                </li>
              </ul>
            </Box>
            <Box sx={{ marginRight: "20px" }}>
              <Typography
                variant="h6"
                sx={{ fontSize: 16, fontWeight: "bold", marginBottom: "10px" }}
                className="visa-type"
              >
                Graduate Visas
              </Typography>
              <ul className="menu-item-li">
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/skilled-graduate-485-visa"
                  >
                    Skilled Temporary Graduate (Subclass 485)
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/skilled-recognised-graduate-476-visa"
                  >
                    Skilled Recognised Graduate (Subclass 476)
                  </Link>
                </li>
              </ul>

              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", marginBottom: "10px" }}
                variant="h6"
                className="visa-type"
              >
                Visitor Visas
              </Typography>
              <ul className="menu-item-li">
                <li>
                  <Link className="menu-item-li-a" to="/visitor-visa-600">
                    Visitor (Subclass 600)
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/electronic-travel-authority-visa-601"
                  >
                    Electronic Travel Authority (Subclass 601)
                  </Link>
                </li>
                <li>
                  <Link className="menu-item-li-a" to="/evisitor-visa-651">
                    eVisitor (Subclass 651)
                  </Link>
                </li>
              </ul>

              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", marginBottom: "10px" }}
                variant="h6"
                className="visa-type"
              >
                Tourist Visas
              </Typography>

              <ul className="menu-item-li">
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/work-and-holiday-visa-462"
                  >
                    Work and Holiday (Subclass 462)
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/working-holiday-visa-417"
                  >
                    Working Holiday (Subclass 417)
                  </Link>
                </li>
              </ul>

              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", marginBottom: "10px" }}
                variant="h6"
                className="visa-type"
              >
                STUDENT & TRAINING VISAS
              </Typography>

              <ul className="menu-item-li">
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/australia-student-visa-500"
                  >
                    Student (Subclass 500)
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/training-visa-subclass-407"
                  >
                    Training (Subclass 407)
                  </Link>
                </li>
              </ul>
            </Box>
            <Box sx={{ marginRight: "20px" }}>
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", marginBottom: "10px" }}
                variant="h6"
                className="visa-type"
              >
                Partner Visas
              </Typography>
              <ul className="menu-item-li">
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/onshore-partner-visa-subclass-820-801"
                  >
                    Onshore Partner (Subclass 820/801)
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/offshore-partner-visa-309-100"
                  >
                    Offshore Partner (Subclass 309/100)
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/prospective-marriage-visa-300"
                  >
                    Prospective Marriage (Subclass 300)
                  </Link>
                </li>
              </ul>
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", marginBottom: "10px" }}
                variant="h6"
                className="visa-type"
              >
                Parents Visas
              </Typography>
              <ul className="menu-item-li">
                <li>
                  <Link className="menu-item-li-a" to="/parent-visa-103">
                    Parent (Subclass 103)
                  </Link>
                </li>
                <li>
                  <Link className="menu-item-li-a" to="/aged-parent-visa-804">
                    Aged Parent (Subclass 804)
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/contributory-parent-visa-143"
                  >
                    Contributory Parent (Subclass 143)
                  </Link>
                </li>

                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/contributory-parent-visa-173"
                  >
                    Contributory Parent (Subclass 173)
                  </Link>
                </li>

                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/contributory-aged-parent-visa-864"
                  >
                    Contributory Aged Parent (Subclass 864)
                  </Link>
                </li>

                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/contributory-aged-parent-visa-884"
                  >
                    Contributory Aged Parent (Subclass 884)
                  </Link>
                </li>
              </ul>
            </Box>
            <Box sx={{ marginRight: "20px" }}></Box>
            <Box>
              <Typography
                sx={{ fontSize: 15, fontWeight: "bold", marginBottom: "10px" }}
                variant="h6"
                className="visa-type"
              >
                Appeals, Review and Cancellations
              </Typography>

              <ul className="menu-item-li">
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/administrative-appeals-tribunal-aat"
                  >
                    AAT
                  </Link>
                </li>
                <li>
                  <Link
                    className="menu-item-li-a"
                    to="/notice-of-intention-to-consider-cancellation-noicc"
                  >
                    NOICC
                  </Link>
                </li>
                <li>
                  <Link className="menu-item-li-a" to="/cancel-a-visa">
                    Visa Cancellation
                  </Link>
                </li>
              </ul>
            </Box>
          </Box>
        );
      case "student":
        return (
          <>
            <MenuItem className="menu-item" component={NavLink} to="/action">
              Action
            </MenuItem>
            <MenuItem
              className="menu-item"
              component={NavLink}
              to="/another-action"
            >
              Another action
            </MenuItem>
            <MenuItem
              className="menu-item"
              component={NavLink}
              to="/something-else"
            >
              Something else here
            </MenuItem>
          </>
        );
      case "profile":
        return (
          <>
            <MenuItem className="menu-item" component={NavLink} to="/account">
              Account
            </MenuItem>
            {!auth.user ? (
              <>
                <MenuItem className="menu-item" component={NavLink} to="/login">
                  Login
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  component={NavLink}
                  to="/register"
                >
                  Signup
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem
                  onClick={handleLogout}
                  className="menu-item"
                  component={NavLink}
                  to="/login"
                >
                  Logout
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  component={NavLink}
                  to={`/dashboard/${auth?.user?.role === 1 ? "admin" : "user"}`}
                >
                  Dashboard
                </MenuItem>
              </>
            )}
          </>
        );
      default:
        return null;
    }
  };
  const handleButtonMouseLeave = () => {
    if (!anchorEl) {
      handleMenuClose();
    }
  };

  const drawerContent = (
    <Box
      sx={{
        width: 250,
        display: { xs: "block", sm: "block", md: "block", lg: "none" },
      }}
      role="presentation"
      onClick={(event) => event.stopPropagation()}
    >
      <List>
        <ListItem component={NavLink} to="/">
          <Button component={NavLink} to="/search" sx={{ color: "black" }}>
            Search
          </Button>
        </ListItem>
        <ListItem button component={NavLink} to="/about">
          <Button
            className="nav-button"
            component={NavLink}
            onMouseEnter={(e) => handleMenuOpen(e, "about")}
            onMouseLeave={handleButtonMouseLeave}
            sx={{ color: "black" }}
          >
            About Us <ExpandMoreIcon sx={{ fontSize: 17 }} />
          </Button>
        </ListItem>
        <ListItem button component={NavLink} to="/visas">
          <Button
            className="nav-button"
            component={NavLink}
            onMouseEnter={(e) => handleMenuOpen(e, "visas")}
            onMouseLeave={handleButtonMouseLeave}
            sx={{ color: "black" }}
          >
            Visas <ExpandMoreIcon sx={{ fontSize: 17 }} />
          </Button>
        </ListItem>
        <ListItem button component={NavLink} to="/student">
          <Button
            className="nav-button"
            component={NavLink}
            onMouseOver={(e) => handleMenuOpen(e, "student")}
            onMouseLeave={handleButtonMouseLeave}
            sx={{ color: "black" }}
          >
            Student Information Hub <ExpandMoreIcon sx={{ fontSize: 17 }} />
          </Button>
        </ListItem>
        <ListItem button component={NavLink} to="/blogs">
          <Button sx={{ color: "black" }} component={NavLink} to="/blogs">
            Blogs
          </Button>
        </ListItem>
        <ListItem button component={NavLink} to="/apply-visa">
          <Button sx={{ color: "black" }} component={NavLink} to="/apply-visa">
            Apply Visa
          </Button>
        </ListItem>
        <ListItem button component={NavLink}>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#cb0731" }}
            component={NavLink}
            to="/appointment"
          >
            Appointment
          </Button>
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to="/profile"
          sx={{ display: { xs: "block", sm: "none", md: "none", lg: "none" } }}
        >
          <Button
            className="nav-button"
            sx={{ color: "black" }}
            component={NavLink}
            onMouseOver={(e) => handleMenuOpen(e, "profile")}
            onMouseLeave={handleButtonMouseLeave}
          >
            Profile <ExpandMoreIcon sx={{ fontSize: 17 }} />
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="sticky" className="header">
      <Toolbar
        className="toolbar"
        sx={{ backgroundColor: "white", height: "90px", zIndex: 1201 }}
      >
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
            mr: 5,
          }}
        >
          <NavLink to="/">
            <img
              src={logo}
              alt="Real Migration Logo"
              style={{ height: "55px", width: "100%" }}
            />
          </NavLink>
        </Box>
        {isMobile ? (
          <>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <NavLink to="/">
              <img
                src={logo}
                alt="Real Migration Logo"
                style={{ height: "55px", width: "100%" }}
              />
            </NavLink>
            <IconButton sx={{ color: "black" }} onClick={toggleSearch}>
              {<SearchIcon />}
            </IconButton>
            <Box
              sx={{
                display: { xs: "none", sm: "block", md: "block", lg: "none" },
              }}
            >
              <Button
                className="nav-button"
                sx={{ color: "black" }}
                component={NavLink}
                onMouseOver={(e) => handleMenuOpen(e, "profile")}
                onMouseLeave={handleButtonMouseLeave}
              >
                <PersonIcon /> <ExpandMoreIcon />
              </Button>
            </Box>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
              {drawerContent}
            </Drawer>
          </>
        ) : (
          <React.Fragment>
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "none", lg: "block" },
                flexWrap: "wrap",
              }}
            >
              <div className="nav-links">
                <Typography
                  className="nav-button"
                  component={NavLink}
                  to="/search"
                  sx={{ color: "black" }}
                >
                  Search
                </Typography>

                <div className="dropdownmenu">
                  <Typography
                    className="nav-button"
                    component="a"
                    href="#"
                    sx={{ color: "black", textDecoration: "none" }}
                  >
                    About Us <ExpandMoreIcon sx={{ fontSize: 16 }} />
                  </Typography>
                  <ul id="submenu-about">
                    <li>
                      <NavLink to="/about">About</NavLink>
                    </li>
                    <li>
                      <NavLink to="/contact">Contact Us</NavLink>
                    </li>
                    <li>
                      <NavLink to="/team">Our Team</NavLink>
                    </li>
                    <li>
                      <NavLink to="/services">Services</NavLink>
                    </li>
                  </ul>
                </div>

                <div className="dropdownmenu">
                  <Typography
                    className="nav-button"
                    component="a"
                    href="#"
                    sx={{ color: "black", textDecoration: "none" }}
                  >
                    Visas <ExpandMoreIcon sx={{ fontSize: 16 }} />
                  </Typography>
                  <div id="submenu-visas" className="visas-menu">
                    <ul className="menu-item-li">
                      <li className="menu-item-li-a">
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          {" "}
                          Skilled Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/skilledindependent-visa-189"
                            >
                              Skilled Independent (Subclass 189)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/skilled-nominated-visa-190"
                            >
                              Skilled Nominated (Subclass 190)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/skilled-work-visa-491"
                            >
                              Skilled Work Regional (Subclass 491)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/permanent-residence-visa-191"
                            >
                              Permanent Residence Regional (Subclass 191)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/skilled-residence-visa-887"
                            >
                              Skilled Residence (Subclass 887)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          Temporary Active Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />{" "}
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/temporary-activity-408-visa"
                            >
                              Temporary Activity (Subclass 408)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/short-stay-visa-400"
                            >
                              Temporary Work Visa(Subclass 400)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          Employer Sponsored Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/temporary-skills-shortage-visa-482"
                            >
                              Temporary Skill Shortage (Subclass 482)
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/employer-nomination-186-visa"
                            >
                              Employer Nomination (Subclass 186)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/skilled-employer-sponsored-regional-494-visa"
                            >
                              Skill Employer Sponsored Regional (Subclass 494)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          {" "}
                          Graduate Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/skilled-graduate-485-visa"
                            >
                              Skilled Temporary Graduate (Subclass 485)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/skilled-recognised-graduate-476-visa"
                            >
                              Skilled Recognised Graduate (Subclass 476)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          {" "}
                          Visitor Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/visitor-visa-600"
                            >
                              Visitor (Subclass 600)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/electronic-travel-authority-visa-601"
                            >
                              Electronic Travel Authority (Subclass 601)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/evisitor-visa-651"
                            >
                              eVisitor (Subclass 651)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          Tourist Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/work-and-holiday-visa-462"
                            >
                              Work and Holiday (Subclass 462)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/working-holiday-visa-417"
                            >
                              Working Holiday (Subclass 417)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          Student & Training Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/australia-student-visa-500"
                            >
                              Student (Subclass 500)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/training-visa-subclass-407"
                            >
                              Training (Subclass 407)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          Partner Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/onshore-partner-visa-subclass-820-801"
                            >
                              Onshore Partner (Subclass 820/801)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/offshore-partner-visa-309-100"
                            >
                              Offshore Partner (Subclass 309/100)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/prospective-marriage-visa-300"
                            >
                              Prospective Marriage (Subclass 300)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          {" "}
                          Parents Visas{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/parent-visa-103"
                            >
                              Parent (Subclass 103)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/aged-parent-visa-804"
                            >
                              Aged Parent (Subclass 804)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/contributory-parent-visa-143"
                            >
                              Contributory Parent (Subclass 143)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/contributory-parent-visa-173"
                            >
                              Contributory Parent (Subclass 173)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/contributory-aged-parent-visa-864"
                            >
                              Contributory Aged Parent (Subclass 864)
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/contributory-aged-parent-visa-884"
                            >
                              Contributory Aged Parent (Subclass 884)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-li-a">
                        {" "}
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            fontSize: "16px",
                          }}
                        >
                          Appeals, Review and Cancellations{" "}
                          <KeyboardArrowRightIcon sx={{ fontSize: 16 }} />
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/administrative-appeals-tribunal-aat"
                            >
                              AAT
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/notice-of-intention-to-consider-cancellation-noicc"
                            >
                              NOICC
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item-li-a"
                              to="/cancel-a-visa"
                            >
                              Visa Cancellation
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="dropdownmenu">
                  <Typography
                    className="nav-button"
                    component="a"
                    href="#"
                    sx={{ color: "black", textDecoration: "none" }}
                  >
                    Student Information Hub{" "}
                    <ExpandMoreIcon sx={{ fontSize: 16 }} />
                  </Typography>
                  <ul id="submenu-student">
                    <li>
                      <a href="#">Action</a>
                    </li>
                    <li>
                      <a href="#">Another action</a>
                    </li>
                    <li>
                      <a href="#">Something else here</a>
                    </li>
                  </ul>
                </div>

                <Typography
                  className="nav-button"
                  sx={{ color: "black" }}
                  component={NavLink}
                  to="/blogs"
                >
                  Blogs
                </Typography>
                <Typography
                  className="nav-button"
                  sx={{ color: "black" }}
                  component={NavLink}
                  to="/apply-visa"
                >
                  Apply Visa
                </Typography>
                <Typography
                  className="nav-button"
                  variant="contained"
                  sx={{
                    backgroundColor: "#cb0731",
                    border: "solid",
                    borderColor: "white",
                    padding: "10px",
                    borderRadius: 1,
                    color: "white ",

                    mr: 3,
                  }}
                  component={NavLink}
                  to="/appointment"
                >
                  Appointment
                </Typography>

                <div className="dropdownmenu">
                  <Typography
                    className="nav-button"
                    component="a"
                    href="#"
                    sx={{ color: "black", textDecoration: "none" }}
                  >
                    Profile <ExpandMoreIcon sx={{ fontSize: 16 }} />
                  </Typography>
                  <ul id="submenu-profile">
                    <MenuItem
                      className="menu-item"
                      component={NavLink}
                      to="/account"
                    >
                      Account
                    </MenuItem>
                    {!auth.user ? (
                      <>
                        <MenuItem
                          className="menu-item"
                          component={NavLink}
                          to="/login"
                        >
                          Login
                        </MenuItem>
                        <MenuItem
                          className="menu-item"
                          component={NavLink}
                          to="/register"
                        >
                          Signup
                        </MenuItem>
                      </>
                    ) : (
                      <>
                        <MenuItem
                          onClick={handleLogout}
                          className="menu-item"
                          component={NavLink}
                          to="/login"
                        >
                          Logout
                        </MenuItem>
                        <MenuItem
                          className="menu-item"
                          component={NavLink}
                          to={`/dashboard/${
                            auth?.user?.role === 1 ? "admin" : "user"
                          }`}
                        >
                          Dashboard
                        </MenuItem>
                      </>
                    )}
                  </ul>
                </div>

                <IconButton sx={{ color: "black" }} onClick={toggleSearch}>
                  {<SearchIcon />}
                </IconButton>
              </div>
            </Box>{" "}
          </React.Fragment>
        )}
      </Toolbar>
      {isSearchOpen && (
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            backgroundColor: "white",
          }}
        >
          <div className="search-bar">
            <input
              type="text"
              className="form-control search-bar-text"
              placeholder="Search here -----------"
            />
          </div>
          <IconButton
            sx={{ color: "black", backgroundColor: "white" }}
            onClick={toggleSearch}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { padding: "10px", marginTop: "35px", justifyItems: "center" },
          onMouseEnter: () => clearTimeout(timer),
          onMouseLeave: handleMenuClose,
        }}
      >
        {renderMenu(menuType)}
      </Popover>
    </AppBar>
  );
};

export default Header;
